import Vue from 'vue';
import Router from 'vue-router';
// import GuideEB from './views/GuideEB.vue';
import IndexView from './views/IndexView.vue';
import GuideFilterViewEB from './views/GuideFilterViewEB.vue';
import GuideFilterViewMS from './views/GuideFilterViewMS.vue';
import GuideFilterViewEM from './views/GuideFilterViewEM.vue';
import GuideFilterViewSB from './views/GuideFilterViewSB.vue';
import UtbytesguidenFilterView from './views/UtbytesguidenFilterView.vue';
import UtbytesguidenEbFilterView from './views/UtbytesguidenEbFilterView.vue';
import ProductsListViewEB from './views/ProductsListViewEB.vue';
import ProductsListViewMS from './views/ProductsListViewMS.vue';
import ProductsListViewEM from './views/ProductsListViewEM.vue';
import ProductsListViewLH from './views/ProductsListViewLH.vue';
import ProductsListViewSB from './views/ProductsListViewSB.vue';
import ProductsListViewNB from './views/ProductsListViewNB.vue';
import Product from './views/Product.vue'
import PrintOrderView from './views/PrintOrderView.vue';

import UserLogin from './views/UserLogin.vue';

import AdminOptions from './views/admin/AdminOptions.vue';
import AdminDashboard from './views/admin/AdminDashboard.vue';
import AdminUtbytesguiden from './views/admin/AdminUtbytesguiden.vue';
import AdminUtbytesguidenEb from './views/admin/AdminUtbytesguidenEb.vue';
import AdminOversattningar from './views/admin/AdminOversattningar.vue';
import AdminEbEdit from './views/admin/AdminEbEdit.vue';
import AdminEmEdit from './views/admin/AdminEmEdit.vue';
import AdminSbEdit from './views/admin/AdminSbEdit.vue';
import AdminNbEdit from './views/admin/AdminNbEdit.vue';
import AdminLhEdit from './views/admin/AdminLhEdit.vue';
import AdminMsEdit from './views/admin/AdminMsEdit.vue';
import AdminLog from './views/admin/AdminLog.vue';
import NotFound from './views/messages/NotFound.vue';
import NetworkIssue from './views/messages/NetworkIssue.vue';
import NProgress from 'nprogress';
import store from './store/store';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'index',
      component: IndexView
    },
    {
      path: '/:language',
      name: 'index-lang',
      component: IndexView
    },
    {
      path: "/guide/elslutbleck",
      name: "guide-filter-eb",
      component: GuideFilterViewEB,
    },
    {
      path: "/guide/elslutbleck",
      name: "guide-filter-eb-start",
      component: GuideFilterViewEB,
    },
    {
      path: "/guide/elslutbleck/edit", /* Use when not to reset options and result */
      name: "guide-filter-eb-edit",
      component: GuideFilterViewEB,
    },
    {
      path: "/guide/elslutbleck/em", /* Use when not to reset options and result */
      name: "guide-filter-eb-with-em",
      meta: { hideFilter: true },
      component: GuideFilterViewEB,
    },
    {
      path: "/guide/montagestolpar",
      name: "guide-filter-ms",
      component: GuideFilterViewMS,
    },
    {
      path: "/guide/montagestolpar", /* Use when not to reset options and result */
      name: "guide-filter-ms-start",
      component: GuideFilterViewMS,
    },
    {
      path: "/guide/montagestolpar/edit", /* Use when not to reset options and result */
      name: "guide-filter-ms-edit",
      component: GuideFilterViewMS,
    },
    {
      path: "/guide/eltryckeslas-motorlas",
      name: "guide-filter-em",
      component: GuideFilterViewEM,
    },
    {
      path: "/guide/eltryckeslas-motorlas",
      name: "guide-filter-em-start",
      component: GuideFilterViewEM,
    },
    {
      path: "/guide/eltryckeslas-motorlas/edit",
      name: "guide-filter-em-edit",
      component: GuideFilterViewEM,
    },
    {
      path: "/guide/slutbleck",
      name: "guide-filter-sb",
      component: GuideFilterViewSB,
    },
    {
      path: "/guide/slutbleck/em",
      name: "guide-filter-sb-with-em",
      component: GuideFilterViewSB,
    },
    {
      path: "/guide/slutbleck",
      name: "guide-filter-sb-start",
      component: GuideFilterViewSB,
    },
    {
      path: "/produkter/elslutbleck",
      name: "products-eb",
      component: ProductsListViewEB,
    },
    {
      path: "/produkter/lashus",
      name: "products-lh",
      component: ProductsListViewLH,
    },
    {
      path: "/produkter/monteringsstolpar",
      name: "products-ms",
      component: ProductsListViewMS,
    },
    {
      path: "/produkter/eltryckeslas-motorlas",
      name: "products-em",
      component: ProductsListViewEM,
    },
    {
      path: "/produkter/slutbleck",
      name: "products-sb",
      component: ProductsListViewSB,
    },
    {
      path: "/produkter/nodutrymningsbeslag",
      name: "products-nb",
      component: ProductsListViewNB,
    },
    {
      path: "/produkt/:id",
      name: "product",
      component: Product,
      props: true
    },
    {
      path: "/utbytesguiden",
      name: "guide-filter-ug",
      component: UtbytesguidenFilterView,
    },
    {
      path: "/utbytesguiden-eb",
      name: "guide-filter-ug-eb",
      component: UtbytesguidenEbFilterView,
    },
    {
      path: "/order",
      name: "print-order",
      component: PrintOrderView,
    },
    {
      path: "/admin/options",
      name: "options",
      component: AdminOptions,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/dashboard",
      name: "adm-dashboard",
      component: AdminDashboard,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/utbytesguiden",
      name: "adm-utbytesguiden",
      component: AdminUtbytesguiden,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/utbytesguiden-eb",
      name: "adm-utbytesguiden-eb",
      component: AdminUtbytesguidenEb,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/oversattningar",
      name: "adm-oversattningar",
      component: AdminOversattningar,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/eb/:id",
      name: "adm-eb-edit",
      component: AdminEbEdit,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/eb/add",
      name: "adm-eb-add",
      component: AdminEbEdit,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/em/:id",
      name: "adm-em-edit",
      component: AdminEmEdit,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/em/add",
      name: "adm-em-add",
      component: AdminEmEdit,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/sb/:id",
      name: "adm-sb-edit",
      component: AdminSbEdit,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/sb/add",
      name: "adm-sb-add",
      component: AdminSbEdit,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/nb/:id",
      name: "adm-nb-edit",
      component: AdminNbEdit,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/nb/add",
      name: "adm-nb-add",
      component: AdminNbEdit,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/lh/:id",
      name: "adm-lh-edit",
      component: AdminLhEdit,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/lh/add",
      name: "adm-lh-add",
      component: AdminLhEdit,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/ms/:id",
      name: "adm-ms-edit",
      component: AdminMsEdit,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/ms/add",
      name: "adm-ms-add",
      component: AdminMsEdit,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/log",
      name: "adm-log",
      component: AdminLog,
      meta: { requiresAuth: true }
    },
    {
      path: "/user/login",
      name: "login",
      component: UserLogin
    },
    {
      path: "/404",
      name: "404",
      component: NotFound
    },
    {
      path: '/network-issue',
      name: 'network-issue',
      component: NetworkIssue
    },
    {
      path: "*",
      redirect: { name: '404' }
    }
  ],
  // scrollBehavior(to, from, savedPosition) {
  scrollBehavior(to) {
    // return desired position
    return new Promise((resolve) => {
      setTimeout(() => {
        if (to.hash) {
          resolve({
            selector: to.hash,
            behavior: 'smooth',
          })
        } else {
          resolve({ x: 0, y: 0 })
        }
      }, 500)
    })
  }
})

router.beforeEach((to, from, next) => {

  const loggedInLocalStorage = JSON.parse(localStorage.getItem('user'));

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedInLocalStorage) {
    store.dispatch('user/logout')
    next({ name: 'login' })
    return
  }

  NProgress.start()

  if (
    // Start over
    to.name == "guide-filter-eb-start" ||
    to.name == "guide-filter-ms-start" ||
    to.name == "guide-filter-em-start" ||
    to.name == "guide-filter-sb-start") {
    // Should run startup function to clear data
    store.dispatch('clearAllData')
  } else if (
    // Edit search selection
    to.name == "guide-filter-eb-edit" ||
    // to.name == "guide-filter-ms-edit" ||
    to.name == "guide-filter-em-edit" ||
    to.name == "guide-filter-sb-edit") {
    store.dispatch('clearAllData', { clearSelection: false, clearResult: false })
  }

  next()
})

router.afterEach(() => {
  NProgress.done()
  Vue.nextTick(() => {
    document.title = process.env.VUE_APP_TITLE
  })
})

export default router